import { Navigate, useRoutes } from 'react-router-dom';
import AdminConsoleContainer from './features/adminConsole/containers/adminConsoleContainer';
import HoastHomePage from './features/hoastHomePage/hoastHomePageContainer';
import Page404 from './features/page404/Page404';
import DashboardLayout from './features/dashboard';
import DashboardHomePage from './features/dashboard/pages/DashboardHomePage';
import ConfirmationListContainer from './features/confirmationList/containers/confirmationListContainer';
import FaqContainer from './features/faq/containers/faqContainer';

export default function Router() {
  const routes = useRoutes([
    {
      path: '',
      element: <HoastHomePage />,
    },
    {
      path: '/ovc',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/ovc/home" />, index: true },
        { path: 'home', element: <DashboardHomePage /> },
        { path: 'trainings', element: <ConfirmationListContainer listsToDisplay={["Beginner Training", "Fundamental Training", "Experienced Training"]} /> },
        { path: 'socialgames', element: <ConfirmationListContainer listsToDisplay={["Casual Social Games", "Experienced Social Games"]} /> },
        { path: 'faq', element: <FaqContainer /> },
        { path: 'admin', element: <AdminConsoleContainer /> },
        { path: '404', element: <Page404 siteName="OVC" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '404',
      element: <Page404 siteName="Hoast" />
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    
  ]);

  return routes;
}
