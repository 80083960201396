import { headerIndex } from "../types/OVCRegistrationHeaderIndexes"
import { Experience, Gender, Player, SessionType, SkillLevel } from "../types/PlayerDetails"
import {v4 as uuidv4} from 'uuid';

function getPlayerID(fullName: string): string {
  return "PLAYER_ID_" + fullName + "_"+ uuidv4()
}

function numStringToDate(dateString: string, time: string): Date {
    const sixDigitString = "0".repeat(6 - time.length) + time;
    const date = new Date(dateString);
    date.setHours(Number(sixDigitString.slice(0,2)));
    date.setMinutes(Number(sixDigitString.slice(2,4)));
    date.setSeconds(Number(sixDigitString.slice(4,6)));
    return date;
  }

  function dateStringToAge(dateString: string): number {
    const [dayStr, monthStr, yearStr] = dateString.split('-');
    const day = parseInt(dayStr, 10);
    const month = parseInt(monthStr, 10) - 1;
    const year = parseInt(yearStr, 10) + 2000;
  
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return 0;
    }

    var today = new Date();
    var birthDate = new Date(year, month, day);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

export function parseRow(row: string[]): Player[] {
    let players: Player[] = []
    if (row[headerIndex.Player1FirstName] != null && row[headerIndex.Player1FirstName].length > 0) {
      players.push({
        id: getPlayerID(row[headerIndex.Player1FirstName] + row[headerIndex.Player1MiddleName] + row[headerIndex.Player1Surname]),
        RegistrationDate: numStringToDate(row[headerIndex.RegistrationDate], row[headerIndex.RegistrationTime]).toISOString(),
        FullName: row[headerIndex.Player1FirstName] + " " + row[headerIndex.Player1MiddleName]+ " " + row[headerIndex.Player1Surname],
        Gender:  row[headerIndex.Player1Gender] as Gender,
        Age: dateStringToAge(row[headerIndex.Player1DateofBirth]),
        Session: row[headerIndex.Player1Session] as SessionType,
        HasRegisteredLastThreeMonths: row[headerIndex.Player1RecentRegistration] === "Yes",
        Experience:  row[headerIndex.Player1VolleyballExperience] as Experience,
        SkillLevel:  row[headerIndex.Player1VolleyballSkillLevel] as SkillLevel,
        Involvement:  row[headerIndex.Player1VolleyballInvolvement],
        InvolvementOther:  row[headerIndex.Player1VolleyballInvolvementOther],
        Frequency:  row[headerIndex.Player1VolleyballFrequency],
        Notes:  row[headerIndex.Player1Notes],
      })
    }
    if (row[headerIndex.Player2FirstName] != null && row[headerIndex.Player2FirstName].length > 0) {
      players.push({
        id: getPlayerID(row[headerIndex.Player2FirstName] + row[headerIndex.Player2MiddleName] + row[headerIndex.Player2Surname]),
        RegistrationDate: numStringToDate(row[headerIndex.RegistrationDate], row[headerIndex.RegistrationTime]).toISOString(),
        FullName: row[headerIndex.Player2FirstName] + " " + row[headerIndex.Player2MiddleName] + " " + row[headerIndex.Player2Surname],
        Gender:  row[headerIndex.Player2Gender] as Gender,
        Age: dateStringToAge(row[headerIndex.Player2DateofBirth]),
        Session: row[headerIndex.Player2Session] as SessionType,
        HasRegisteredLastThreeMonths: row[headerIndex.Player2RecentRegistration] === "Yes",
        Experience:  row[headerIndex.Player2VolleyballExperience] as Experience,
        SkillLevel:  row[headerIndex.Player2VolleyballSkillLevel] as SkillLevel,
        Involvement:  row[headerIndex.Player2VolleyballInvolvement],
        InvolvementOther:  row[headerIndex.Player2VolleyballInvolvementOther],
        Frequency:  row[headerIndex.Player2VolleyballFrequency],
        Notes:  row[headerIndex.Player2Notes],
      })
    }
    if (row[headerIndex.Player3FirstName] != null && row[headerIndex.Player3FirstName].length > 0) {
      players.push({
        id: getPlayerID(row[headerIndex.Player3FirstName] + row[headerIndex.Player3MiddleName] + row[headerIndex.Player3Surname]),
        RegistrationDate: numStringToDate(row[headerIndex.RegistrationDate], row[headerIndex.RegistrationTime]).toISOString(),
        FullName: row[headerIndex.Player3FirstName] + " " + row[headerIndex.Player3MiddleName]+ " " + row[headerIndex.Player3Surname],
        Gender:  row[headerIndex.Player3Gender] as Gender,
        Age: dateStringToAge(row[headerIndex.Player3DateofBirth]),
        Session: row[headerIndex.Player3Session] as SessionType,
        HasRegisteredLastThreeMonths: row[headerIndex.Player3RecentRegistration] === "Yes",
        Experience:  row[headerIndex.Player3VolleyballExperience] as Experience,
        SkillLevel:  row[headerIndex.Player3VolleyballSkillLevel] as SkillLevel,
        Involvement:  row[headerIndex.Player3VolleyballInvolvement],
        InvolvementOther:  row[headerIndex.Player3VolleyballInvolvementOther],
        Frequency:  row[headerIndex.Player3VolleyballFrequency],
        Notes:  row[headerIndex.Player3Notes],
      })
    }
    if (row[headerIndex.Player4FirstName] != null && row[headerIndex.Player4FirstName].length > 0) {
      players.push({
        id: getPlayerID(row[headerIndex.Player4FirstName]+ " " + row[headerIndex.Player4MiddleName]+ " " + row[headerIndex.Player4Surname]),
        RegistrationDate: numStringToDate(row[headerIndex.RegistrationDate], row[headerIndex.RegistrationTime]).toISOString(),
        FullName: row[headerIndex.Player4FirstName] + row[headerIndex.Player4MiddleName] + row[headerIndex.Player4Surname],
        Gender:  row[headerIndex.Player4Gender] as Gender,
        Age: dateStringToAge(row[headerIndex.Player4DateofBirth]),
        Session: row[headerIndex.Player4Session] as SessionType,
        HasRegisteredLastThreeMonths: row[headerIndex.Player4RecentRegistration] === "Yes",
        Experience:  row[headerIndex.Player4VolleyballExperience] as Experience,
        SkillLevel:  row[headerIndex.Player4VolleyballSkillLevel] as SkillLevel,
        Involvement:  row[headerIndex.Player4VolleyballInvolvement],
        InvolvementOther:  row[headerIndex.Player4VolleyballInvolvementOther],
        Frequency:  row[headerIndex.Player4VolleyballFrequency],
        Notes:  row[headerIndex.Player4Notes],
      })
    }
    if (row[headerIndex.Player5FirstName] != null && row[headerIndex.Player5FirstName].length > 0) {
      players.push({
        id: getPlayerID(row[headerIndex.Player5FirstName]+ " " + row[headerIndex.Player5MiddleName]+ " " + row[headerIndex.Player5Surname]),
        RegistrationDate: numStringToDate(row[headerIndex.RegistrationDate], row[headerIndex.RegistrationTime]).toISOString(),
        FullName: row[headerIndex.Player5FirstName] + row[headerIndex.Player5MiddleName] + row[headerIndex.Player5Surname],
        Gender:  row[headerIndex.Player5Gender] as Gender,
        Age: dateStringToAge(row[headerIndex.Player5DateofBirth]),
        Session: row[headerIndex.Player5Session] as SessionType,
        HasRegisteredLastThreeMonths: row[headerIndex.Player5RecentRegistration] === "Yes",
        Experience:  row[headerIndex.Player5VolleyballExperience] as Experience,
        SkillLevel:  row[headerIndex.Player5VolleyballSkillLevel] as SkillLevel,
        Involvement:  row[headerIndex.Player5VolleyballInvolvement],
        InvolvementOther:  row[headerIndex.Player5VolleyballInvolvementOther],
        Frequency:  row[headerIndex.Player5VolleyballFrequency],
        Notes:  row[headerIndex.Player5Notes],
      })
    }
    return players;
  }