import { Alert, Box, Button, Card, Snackbar, SnackbarCloseReason, Stack, TextField, Typography } from "@mui/material";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { DataStore } from "@aws-amplify/datastore"
import { Event as EventModel} from "../../../models"
import { CONFIRMATION_EVENT_ID } from "../../../common/contants/event";
import { SyntheticEvent, useEffect, useState } from "react";
import { DateTime } from "luxon";

const EventTimeUpdateCard = () => {

    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  
    const handleClose = (event?: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSuccessSnackbarOpen(false);
    };

    const [eventTitle, setEventTitle] = useState<string | null>("");
    const [eventInfo, setEventInfo] = useState<string | null>("");
    const [host, setHost] = useState<string | null>("");
    const [location, setLocation] = useState<string | null>("");
    const [startTime, setStartTime] = useState<DateTime | null>(DateTime.now());
    const [endTime, setEndTime] = useState<DateTime | null>(DateTime.now());

    async function updateWithExisitingEventTime() {
        const existingEvent = await DataStore.query(EventModel, CONFIRMATION_EVENT_ID);
        setEventTitle(existingEvent?.title ? existingEvent?.title : "");
        setEventInfo(existingEvent?.eventInfo ? existingEvent?.eventInfo : "");
        setHost(existingEvent?.host ? existingEvent?.host : "");
        setLocation(existingEvent?.location ? existingEvent?.location : "");
        setStartTime(existingEvent?.startDateTime ? DateTime.fromISO(existingEvent?.startDateTime) : DateTime.now());
        setEndTime(existingEvent?.endDateTime ? DateTime.fromISO(existingEvent?.endDateTime) : DateTime.now());

    }

    async function updateEventTime(startTime: string, endTime: string, eventTitle: string, eventInfo: string, host: string, location: string) {
        const original = await DataStore.query(EventModel, CONFIRMATION_EVENT_ID);

        if (original) {
            const updatedPost = await DataStore.save(
                EventModel.copyOf(original, updated => {
                    updated.title = eventTitle;
                    updated.startDateTime = startTime;
                    updated.endDateTime = endTime;
                    updated.eventInfo = eventInfo;
                    updated.host = host;
                    updated.location = location;
                })
            );
            console.log("Updated Event Time with:" + JSON.stringify(updatedPost));
            setSuccessSnackbarOpen(true);
        }
    };

    useEffect(() => {
        updateWithExisitingEventTime()
    }, []);

    return (
        <Card sx={{ display: 'inline-flex' }}>
            <Stack component="span" direction="column" alignItems="left" justifyContent="flex-start">
                <Box padding={2}>
                    <TextField fullWidth label="Event Title" variant="outlined" value={eventTitle} onChange={(newTitle) => setEventTitle(newTitle.target.value)}/>
                </Box>
                <Box padding={2}>
                    <TextField fullWidth label="Event Info" variant="outlined" value={eventInfo} onChange={(newInfo) => setEventInfo(newInfo.target.value)}/>
                </Box>
                <Box padding={2}>
                    <TextField fullWidth label="Host" variant="outlined" value={host} onChange={(newHost) => setHost(newHost.target.value)}/>
                </Box>
                <Box padding={2}>
                    <TextField fullWidth label="Location" variant="outlined" value={location} onChange={(newLocation) => setLocation(newLocation.target.value)}/>
                </Box>
                <Stack component="span" direction="row" alignItems="center" justifyContent="flex-start">
                    <Box padding={2}>
                        <MobileDateTimePicker label="Session Start Time" ampm value={startTime} onChange={(newTime) => setStartTime(newTime)} />
                    </Box>
                    to
                    <Box padding={2}>
                        <MobileDateTimePicker label="Session End Time" ampm value={endTime} onChange={(newTime) => setEndTime(newTime)} />
                    </Box>

                    <Box padding={2}>
                        <Button variant="contained" onClick={() => updateEventTime(
                            startTime ? startTime.toISO()! : "", 
                            endTime ? endTime.toISO()! : "",
                            eventTitle ? eventTitle : "",
                            eventInfo ? eventInfo : "",
                            host ? host : "",
                            location ? location : "",
                            )}>Update Session Info</Button>
                    </Box>
                </Stack>
            </Stack>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ position: "fixed", bottom: 10, right: 10 }}>
                    Successfully updated session info!
                </Alert>
            </Snackbar>
        </Card>
    )
}

export default EventTimeUpdateCard;
