import { Container, Divider, Grid, Typography } from "@mui/material"
import FaqRow, { Faq } from "../components/faqRow";

const FaqContainer = () => { 
    const faqs: Faq[] = [
        {
            question: "What is the tick and clock mean for status?",
            answer: "A tick means you have a confirmed status and have secured a spot for that particular session. A clock means pending and you will be contacted as soon as a spot becomes avaliable."
        },
        {
            question: "Why has my name not appeared?",
            answer: "The list is updated manually once a day only. If you have any concerns, you may contact us via email - enquiries@outreachvolleyball.org.au"
        },
        {
            question: "I paid but why do I not have a confirmed status?",
            answer: "Outreach runs a week-by-week format. To provide player flexibility to cancel session, we enable a pending status (limited spots). This ensures we are able to fill spots and players are assured they are given priority."
        },
        {
            question: "How do I know if I can play if I have a pending status?",
            answer: "Outreach will contact you via email and/or mobile if a spot becomes vacant. We do not expect pending players to be avaliable if contacted up to 24 hours prior to session."
        },
    ]

    return (
        <Container maxWidth="lg">
            <Typography variant="h2" gutterBottom>
                Frequently Asked Questions
            </Typography>
            <Divider />        
            <Grid container spacing={5}>
                <FaqRow faqs={faqs} />
            </Grid>
      </Container>
    )
}

export default FaqContainer;