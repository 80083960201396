import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import { Page, PlayerList } from "../types/PageDataStructures";
import { Experience, Player, SessionType, SkillLevel } from "../types/PlayerDetails";
import { Button, Divider, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { Add } from '@mui/icons-material';
import { Gender } from '../types/PlayerDetails';

type CollapsibleTableRowProps = {
  player: Player,
  draggableProvided: DraggableProvided,
  handleDelete: () => void;
}

const CollapsibleTableRow = ({ player, draggableProvided, handleDelete }: CollapsibleTableRowProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment key={player.id}>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}>

        <TableCell component="th" scope="row">
          {player.RegistrationDate}
        </TableCell>
        <TableCell>
          {player.FullName}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="delete row"
            size="small"
            onClick={handleDelete}
          >
            <DeleteIcon/>
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
              >
                <ListItem>
                  <ListItemText primary="Age" secondary={player.Age} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Registered Session" secondary={player.Session} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Has registered in the last 3 months?" secondary={player.HasRegisteredLastThreeMonths ? "Yes": "No"} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Experience" secondary={player.Experience} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Skill level" secondary={player.SkillLevel} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Involvement" secondary={player.Involvement} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Other involvement" secondary={player.InvolvementOther} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Frequency" secondary={player.Frequency} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Notes" secondary={player.Notes} />
                </ListItem>
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

type CollapsibleTableProps = {
  list: PlayerList,
  state: Page,
  setState: (page: Page) => void;
}

const CollapsibleTable = ({ list, state, setState }: CollapsibleTableProps) => {

  const handleDeleteRow = (deletionList: PlayerList, index: number) => {

    const newList = Array.from(list.players);
    newList.splice(index + 1, 1);
    const newPlayerList = {
      ...list,
      players: newList
    }
    setState({...state, 
      lists: [
        ...state.lists.filter(list => list.id !== deletionList.id),
        newPlayerList
      ],
    });
  }

  const handleAddRow = (additonList: PlayerList, name: string) => {
    const newList = Array.from(list.players);
    newList.push({
      id: name,
      RegistrationDate: '',
      FullName: name,
      Gender: "N/A" as Gender,
      Age: 0,
      Session: "N/A" as SessionType,
      HasRegisteredLastThreeMonths: false,
      Experience: "N/A" as Experience,
      SkillLevel: "N/A" as SkillLevel,
      Involvement: "N/A",
      InvolvementOther: "N/A",
      Frequency: "N/A",
      Notes: "N/A"
    })
    const newPlayerList = {
      ...list,
      players: newList
    }
    setState({...state, 
      lists: [
        ...state.lists.filter(list => list.id !== additonList.id),
        newPlayerList
      ],
    });
  }

  return (
    <Box maxWidth={"500px"}>
      <TableContainer component={Paper}>
        <Stack component="span" direction="row" alignItems="center" justifyContent="flex-start">
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            style={{ padding: 20 }}
          >
            {list.title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box padding={1}>
            <Button variant="contained" startIcon={<Add />} onClick={() => handleAddRow(list, "Emerson")}>
              Add Player
            </Button>
          </Box>
        </Stack>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Registration Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <Droppable droppableId={list.id}>
            {(droppableProvided, droppableSnapshot) => (
              <TableBody ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}>
                {list.players.slice(0, list.maxPlayers).map((player, index) => (
                  <Draggable key={player.id} draggableId={`${player.id}`} index={index}>
                    {(draggableProvided, draggableSnapshot) => (
                      <CollapsibleTableRow  key={player.id} player={player} draggableProvided={draggableProvided} handleDelete={() => handleDeleteRow(list, index - 1)}
                    />
                    )}
                  </Draggable>
                ))}
                <TableRow>
                  <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={3}>
                    <Typography
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Waiting List
                    </Typography>
                  </TableCell>
                </TableRow>
                {list.players.slice(list.maxPlayers).map((player, index) => (
                  <Draggable key={player.id} draggableId={`${player.id}`} index={index + list.maxPlayers}>
                    {(draggableProvided, draggableSnapshot) => (
                      <CollapsibleTableRow  key={player.id} player={player} draggableProvided={draggableProvided} handleDelete={() => handleDeleteRow(list, index - 1)}
                    />
                    )}
                  </Draggable>
                ))}
              {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CollapsibleTable;