import { Flex, Heading, Text } from "@chakra-ui/react";
import { Alert, Box, Button, Card, FormControlLabel, Grid, Snackbar, SnackbarCloseReason, Stack, Switch, Typography } from "@mui/material";
import { Authenticator, withAuthenticator } from "@aws-amplify/ui-react";
import React, { SyntheticEvent, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import CSVReader from 'react-csv-reader';
import { parseRow } from "../utils/player";
import CollapsibleTable from "../components/collapsibleTable";
import { Page, PlayerList } from "../types/PageDataStructures";
import { Player, SessionType, sortPlayerRegDateAscending } from "../types/PlayerDetails";
import { DataStore } from "@aws-amplify/datastore"
import { Event as EventModel, Player as PlayerModel, PlayerList as PlayerListModel } from "../../../models"
import { CONFIRMATION_EVENT_ID } from "../../../common/contants/event";
import EventTimeUpdateCard from "../components/eventTimeUpdateCard";
import { DateTime } from "luxon";

const initialData: Page = {
    lists: [],
    // Facilitate reordering of the columns
    listOrder: [],
    title: "",
    host: "",
    location: "",
    startDateTime: "",
    endDateTime: "",
}

const AdminConsoleContainer = () => {
    const [state, setState] = useState<Page>(initialData);
    const [autoSortList, setAutoSortList] = useState<boolean>(true);

    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  
    const handleClose = (event?: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSuccessSnackbarOpen(false);
    };

    function handleFileUpload(csv: string[][]) {
        let players: Player[] = []
        csv.slice(1).forEach(row => players = players.concat(parseRow(row)))

        let beginnerTrainingPlayers: Player[] = [];
        let fundamentalTrainingPlayers: Player[] = [];
        let experiencedTrainingPlayers: Player[] = [];
        let casualSocialGamesPlayer: Player[] = [];
        let experiencedSocialGamesPlayer: Player[] = [];

        players.forEach(player => {
        switch (player.Session) {
            case SessionType.BeginnerTraining:
            beginnerTrainingPlayers.push(player);
            break;
            case SessionType.FundamentalTraining:
            fundamentalTrainingPlayers.push(player);
            break;
            case SessionType.ExperiencedTraining:
            experiencedTrainingPlayers.push(player);
            break;
            case SessionType.CasualSocialGames:
            casualSocialGamesPlayer.push(player);
            break;
            case SessionType.ExperiencedSocialGames:
            experiencedSocialGamesPlayer.push(player);
            break;
        }
        })
        setState(
        {...state,
            lists: [
            {
                id: "beginnerTraining",
                title: "Beginner Training",
                players: beginnerTrainingPlayers.sort(sortPlayerRegDateAscending),
                maxPlayers: 18,
                host: "",
                location: "",
                startDateTime: DateTime.now().toISO()!,
                endDateTime: DateTime.now().toISO()!,
            },
            {
                id: "fundamentalTraining",
                title: "Fundamental Training",
                players: fundamentalTrainingPlayers.sort(sortPlayerRegDateAscending),
                maxPlayers: 18,
                host: "",
                location: "",
                startDateTime: DateTime.now().toISO()!,
                endDateTime: DateTime.now().toISO()!,
            },
            {
                id: "experiencedTraining",
                title: "Experienced Training",
                players: experiencedTrainingPlayers.sort(sortPlayerRegDateAscending),
                maxPlayers: 18,
                host: "",
                location: "",
                startDateTime: DateTime.now().toISO()!,
                endDateTime: DateTime.now().toISO()!,
            },
            {
                id: "casualSocialGames",
                title: "Casual Social Games",
                players: casualSocialGamesPlayer.sort(sortPlayerRegDateAscending),
                maxPlayers: 18,
                host: "",
                location: "",
                startDateTime: DateTime.now().toISO()!,
                endDateTime: DateTime.now().toISO()!,
            },
            {
                id: "experiencedSocialGames",
                title: "Experienced Social Games",
                players: experiencedSocialGamesPlayer.sort(sortPlayerRegDateAscending),
                maxPlayers: 18,
                host: "",
                location: "",
                startDateTime: DateTime.now().toISO()!,
                endDateTime: DateTime.now().toISO()!,
            }
            ],
            listOrder: [
            "beginnerTraining", 
            "fundamentalTraining", 
            "experiencedTraining", 
            "casualSocialGames", 
            "experiencedSocialGames", 
            ],
        }
        )
    }

    const reorderColumnList = (sourceList: PlayerList, startIndex: number, endIndex: number) => {
        const newPlayers = Array.from(sourceList.players);
        const [removed] = newPlayers.splice(startIndex, 1);
        newPlayers.splice(endIndex, 0, removed);
    
        const newList = {
        ...sourceList,
        players: autoSortList ? newPlayers.sort(sortPlayerRegDateAscending) : newPlayers,
        };
    
        return newList;
    };

    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;

        // If user tries to drop in an unknown destination
        if (!destination) return;

        // if the user drags and drops back in the same position
        if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
        ) {
        return;
        }

        // If the user drops within the same column but in a different position
        const sourceList: PlayerList = state.lists.find(list => list.id === source.droppableId)!;
        const destinationList: PlayerList = state.lists.find(list => list.id === destination.droppableId)!;

        if (sourceList.id === destinationList.id) {
        const newList = reorderColumnList(
            sourceList,
            source.index,
            destination.index
        );

        const newState: Page = {
            ...state,
            lists: [
            ...state.lists.filter(list => list.id !== sourceList.id),
            newList,
            ],
        };
        setState(newState);
        return;
        }

        // If the user moves from one column to another
        const startPlayers: Player[] = Array.from(sourceList.players);
        const [removed]: Player[] = startPlayers.splice(source.index, 1);
        const newStartList: PlayerList = {
        ...sourceList,
        players: autoSortList ? startPlayers.sort(sortPlayerRegDateAscending) : startPlayers,
        };

        const endPlayers: Player[] = Array.from(destinationList.players);
        endPlayers.splice(destination.index, 0, removed);
        const newEndList: PlayerList = {
        ...destinationList,
        players: autoSortList ? endPlayers.sort(sortPlayerRegDateAscending): endPlayers,
        };

        const newState: Page = {
        ...state,
        lists: [
            ...state.lists.filter(list => list.id !== newStartList.id && list.id !== newEndList.id),
            newStartList,
            newEndList,
        ],
        };

        setState(newState);
    };

    async function updatePlayerLists() {
        const original = await DataStore.query(EventModel, CONFIRMATION_EVENT_ID);

        if (original) {
            const updatedEvent = await DataStore.save(
                EventModel.copyOf(original, updated => {
                    updated.playerLists = state.listOrder.map(listId => {
                        let list = state.lists.find(list => list.id === listId)!;
                        return new PlayerListModel({
                            title: list.title,
                            maxPlayers: list.maxPlayers,
                            players: list.players.map(player => {
                                return new PlayerModel({
                                    name: player.FullName
                                })
                            }),
                            host: list.host,
                            location: list.location,
                            startDateTime: list.startDateTime ? list.startDateTime : DateTime.now().toISO(),
                            endDateTime: list.endDateTime ? list.endDateTime : DateTime.now().toISO(),
                        })
                    })
                })
            );
            console.log("Updated Event Time with:" + JSON.stringify(updatedEvent));
            setSuccessSnackbarOpen(true);
        }
    }

    return (    
        <Authenticator >
            {({ signOut, user }) => (
                <div>
                    <Stack component="span" direction="row" alignItems="center" justifyContent="flex-start">
                        <Typography variant="h3" padding={1}>
                            Hey {user?.attributes?.email}!
                        </Typography>
                        <Button onClick={signOut} variant="contained">
                            Sign Out
                        </Button>
                    </Stack>
                    <Grid container justifyContent="flex-start">
                        <Grid padding={1} >
                            <EventTimeUpdateCard />
                        </Grid>
                        <Grid padding={1} >
                            <Card sx={{ display: 'inline-flex' }}>
                                <Stack component="span" direction="column" alignItems="left" width="fit-content">
                                    <Stack component="span" direction="row" alignItems="center" justifyContent="flex-start">
                                        <Typography
                                            variant="h6"
                                            id="tableTitle"
                                            component="div"
                                            style={{ padding: 20 }}
                                        >
                                            Upload CSV:
                                        </Typography>
                                        <CSVReader onFileLoaded={handleFileUpload} />
                                    </Stack>
                                    <Box paddingLeft={2}>
                                        <FormControlLabel 
                                            control={
                                                <Switch 
                                                checked={autoSortList}
                                                onChange={(event, checked: boolean) => {
                                                    setAutoSortList(checked)
                                                    let newState: Page = state
                                                    newState.lists.forEach(list => list.players.sort(sortPlayerRegDateAscending))
                                                    setState(newState)
                                                }}
                                                />
                                            } 
                                            label="Auto Sort Rows by Registration Date"
                                        />
                                    </Box>
                                    <Box padding={2} display="flex" justifyContent="flex-end">
                                        <Button
                                            fullWidth={false}
                                            onClick={() => {
                                                updatePlayerLists();
                                            }}
                                            variant="contained"
                                        >
                                            Publish Lists
                                        </Button>
                                    </Box>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                    <DragDropContext onDragEnd={onDragEnd} >
                        <Grid container >
                                <Grid container justifyContent="flex-start">

                                    {state.listOrder.map((listId: string) => {
                                    const list = state.lists.find(list => list.id === listId)!;
                                    return (
                                        <Grid padding={1} key={list.id} item xs={12} sm={6} md={6} lg={4}>
                                            <CollapsibleTable list={list} state={state} setState={setState}/>
                                        </Grid>
                                    )
                                    })}
                                </Grid>
                        </Grid>
                    </DragDropContext>
                    <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ position: "fixed", bottom: 10, right: 10 }}>
                            Successfully published player lists!
                        </Alert>
                    </Snackbar>
                </div>
            )}
        </Authenticator>
    );
}

export default withAuthenticator(AdminConsoleContainer)