import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
// components
import ThemeProvider from "./theme/index";
import ScrollToTop from "./common/components/scroll-to-top";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </LocalizationProvider>
  )
}

export default App;