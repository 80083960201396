export const headerIndex = {
    RegistrationDate: 0,
    RegistrationTime: 1,
    RegistrationStatus: 2,
    NotifyEmailAddresses: 3,
    PrimaryMemberSurname: 4,
    PrimaryMemberFirstName: 5,
    PrimaryMemberMobileNumber: 6,
    PrimaryMemberEmailAddress: 7,
    PrimaryMemberNotes: 8,
    EmergencyContactSurname: 9,
    EmergencyContactFirstName: 10,
    EmergencyContactMobileNumber: 11,
    EmergencyContactEmailAddress: 12,
    EmergencyContactNotes: 13,
    Player1Surname: 14,
    Player1FirstName: 15,
    Player1MiddleName: 16,
    Player1Gender: 17,
    Player1DateofBirth: 18,
    Player1Session: 19,
    Player1RecentRegistration: 20,
    Player1VolleyballExperience: 21,
    Player1VolleyballSkillLevel: 22,
    Player1VolleyballInvolvement: 23,
    Player1VolleyballInvolvementOther: 24,
    Player1VolleyballFrequency: 25,
    Player1Notes: 26,
    Player2Surname: 27,
    Player2FirstName: 28,
    Player2MiddleName: 29,
    Player2Gender: 30,
    Player2DateofBirth: 31,
    Player2Session: 32,
    Player2RecentRegistration: 33,
    Player2VolleyballExperience: 34,
    Player2VolleyballSkillLevel: 35,
    Player2VolleyballInvolvement: 36,
    Player2VolleyballInvolvementOther: 37,
    Player2VolleyballFrequency: 38,
    Player2Notes: 39,
    Player3Surname: 40,
    Player3FirstName: 41,
    Player3MiddleName: 42,
    Player3Gender: 43,
    Player3DateofBirth: 44,
    Player3Session: 45,
    Player3RecentRegistration: 46,
    Player3VolleyballExperience: 47,
    Player3VolleyballSkillLevel: 48,
    Player3VolleyballInvolvement: 49,
    Player3VolleyballInvolvementOther: 50,
    Player3VolleyballFrequency: 51,
    Player3Notes: 52,
    Player4Surname: 53,
    Player4FirstName: 54,
    Player4MiddleName: 55,
    Player4Gender: 56,
    Player4DateofBirth: 57,
    Player4Session: 58,
    Player4RecentRegistration: 59,
    Player4VolleyballExperience: 60,
    Player4VolleyballSkillLevel: 61,
    Player4VolleyballInvolvement: 62,
    Player4VolleyballInvolvementOther: 63,
    Player4VolleyballFrequency: 64,
    Player4Notes: 65,
    Player5Surname: 66,
    Player5FirstName: 67,
    Player5MiddleName: 68,
    Player5Gender: 69,
    Player5DateofBirth: 70,
    Player5Session: 71,
    Player5RecentRegistration: 72,
    Player5VolleyballExperience: 73,
    Player5VolleyballSkillLevel: 74,
    Player5VolleyballInvolvement: 75,
    Player5VolleyballInvolvementOther: 76,
    Player5VolleyballFrequency: 77,
    Player5Notes: 78,
}