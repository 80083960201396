import { Typography, TableBody, TableRow, TableCell, Paper, TablePagination, Container, Avatar, Button, Card, Checkbox, Stack, Table, TableContainer, Icon } from "@mui/material";
import React, { useState } from "react";
import { Player, PlayerList } from "../../../models";
import { UserListToolbar, UserListHead } from "../../dashboard/sections/@dashboard/user";
import { AccessTime, Check, Label } from "@mui/icons-material";
import { Box } from "@chakra-ui/react";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignLeft: true },
    { id: 'status', label: 'Status', alignLeft: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a: Player, b: Player) {
    if (b.name < a.name) {
        return -1;
    }
    if (b.name > a.name) {
        return 1;
    }
    return 0;
}

function getComparator(order: string) {
    return order === 'desc'
        ? (a: Player, b: Player) => descendingComparator(a, b)
        : (a: Player, b: Player) => -descendingComparator(a, b);
}

function applySortFilter(array: Player[] | null, comparator: (a: Player, b: Player) => number, query: string) {
    const newArray = Array.from(array!);
    if (query) {
        return newArray!.filter((player) => player.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return newArray!.sort(comparator);
}

type PlayerListTableProp = {
    playerList: PlayerList,
}

const PlayerListTable = ({ playerList }: PlayerListTableProp) => {
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    // const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleRequestSort = (event: any, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
    //     setPage(0);
    //     setRowsPerPage(parseInt(event.target.value, 10));
    // };

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - playerList.players!.length) : 0;

    const filteredUsers = applySortFilter(playerList.players! as Player[], getComparator(order), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    function isPlayerConfirmed(name: string): boolean {
        console.log(playerList.players!.findIndex(player => player?.name === name))
        return playerList.players!.findIndex(player => player?.name === name) <= playerList.maxPlayers!
    }

    if (!playerList) return(<div/>);

    return (
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={2}>
                    <Typography variant="h2" gutterBottom>
                        {playerList.title}
                    </Typography>
                </Stack>
                <Box display="flex" justifyContent="center">
                    <Card sx={{ maxWidth: 500, minWidth: 300 }} >
                        <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

                        {/* <Scrollbar sx={{ maxWidth: 500, minWidth: 400 }}> */}
                            <TableContainer sx={{ maxWidth: 500, minWidth: 300 }} >
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                                        {filteredUsers.map((row) => {
                                            const { name } = row;
                                            // const selectedUser = selected.indexOf(name) !== -1;

                                            return (
                                                <TableRow key={name} tabIndex={-1} role="checkbox" >
                                                    <TableCell>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {name}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {isPlayerConfirmed(name)
                                                            ? <Check color="success">Checked</Check>
                                                            : <AccessTime color="warning"/>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {/* {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={2} />
                                            </TableRow>
                                        )} */}
                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={2} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        {/* </Scrollbar> */}

                        {/* <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={playerList.players!.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                    </Card>
                </Box>
        </Container>
    )
}

export default PlayerListTable