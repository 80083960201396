import { DataStore } from "@aws-amplify/datastore"
import { useEffect, useState } from "react"
import { Event } from "../../../models"
import { FormGroup, Grid, Box } from "@mui/material";
import { CONFIRMATION_EVENT_ID } from "../../../common/contants/event";
import PlayerListTable from "../components/playerListTable";

const ConfirmationListContainer = ({listsToDisplay}: {listsToDisplay: string[]}) => {

    const [event, setEvent] = useState<Event>()


    useEffect(() => {
        /**
         * This keeps `post` fresh.
         */
        const sub = DataStore.observeQuery(Event, (e) =>
          e.id.eq(CONFIRMATION_EVENT_ID)
        ).subscribe(({ items }) => {
            setEvent(items[0]);
        })

        return () => {
            sub.unsubscribe();
          };
    }, []);

    if (!event) return(<div/>);
    
    return(
        <Box sx={{ flexGrow: 1 }}>
            <Grid container display="flex" justifyContent="center">
                {event?.playerLists?.filter(list => listsToDisplay.includes(list!.title)).map(playerList => {
                    return (
                        <Grid item key={playerList?.title}>
                            <PlayerListTable playerList={playerList!}/>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default ConfirmationListContainer;