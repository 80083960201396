// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Event, Player, PlayerList } = initSchema(schema);

export {
  Event,
  Player,
  PlayerList
};