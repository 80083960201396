import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export type Faq = {
    question: string,
    answer: string,
}
export default function FaqRow(props: {faqs: Faq[]}) {
    const { faqs } = props;

    return (
        <Grid
        item
        >

        {faqs.map((faq) => (
            <Box padding={2} key={faq.question.slice(0,10)}>
                <Typography variant="h3">
                {faq.question}
                </Typography>
                <Box paddingTop={1}>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {faq.answer}
                    </Typography>
                </Box>
            </Box>
        ))}
        </Grid>
    );
}