import hoast_logo from "../assets/hoast_logo.png"
import hoast_logo_words from "../assets/hoast_logo_words.png"

import ovc_circle_logo from "../assets/ovc_circle_logo.png"
import ovc_text from "../assets/ovc_text.png"

export const HOAST_YELLOW_HEX: string = "#EEE833"
export const HOAST_BLUE_HEX: string = "#2EC4B6"
export const HOAST_LOGO = hoast_logo
export const HOAST_LOGO_WORDS = hoast_logo_words

export const OVC_CIRCLE_LOGO = ovc_circle_logo
export const OVC_TEXT = ovc_text
