// component
import SvgColor from '../../../common/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Home',
    path: '/ovc/home',
    icon: icon('ic_home'),
  },
  {
    title: 'Trainings',
    path: '/ovc/trainings',
    icon: icon('ic_trainings'),
  },
  {
    title: 'Social Games',
    path: '/ovc/socialgames',
    icon: icon('ic_games'),
  },
  {
    title: 'FAQ',
    path: '/ovc/faq',
    icon: icon('ic_faq'),
  },
  {
    title: 'Admin',
    path: '/ovc/admin',
    icon: icon('ic_admin'),
  },
];

export default navConfig;
