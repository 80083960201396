export type Player = {
    id: string,
    RegistrationDate: string,
    FullName: string,
    Gender: Gender,
    Age: number,
    Session: SessionType,
    HasRegisteredLastThreeMonths: boolean,
    Experience: Experience,
    SkillLevel: SkillLevel,
    Involvement: string,
    InvolvementOther: string,
    Frequency: string,
    Notes: string,
  }

export enum SessionType {
    BeginnerTraining = "Beginner Training",
    FundamentalTraining = "Fundamental Training",
    ExperiencedTraining = "Experienced Training",
    CasualSocialGames = "Casual Social Games",
    ExperiencedSocialGames = "Experienced Social Games",
    NA = "N/A",
}

export enum Gender {
    Male = "Male",
    Female = "Female",
    Other = "Other",
    NA = "N/A"
}

export enum Experience {
    lessThanSixMonths = "Less Than 6 Months",
    SixToTwelveMonths = "Between 6 to 12 Months",
    OneToTwoYears = "Between 1 to 2 Years",
    MoreThanTwoYears = "More Than 2 Years",
    NA = "N/A",
}

export enum SkillLevel {
    Good = "Very Skilled/Proficient",
    Average = "Average",
    OK = "Beginner/Somewhat Proficient",
    New = "Novice",
    NA = "N/A"
}

export function sortPlayerRegDateAscending(a: Player, b: Player) {
    return new Date(a.RegistrationDate).valueOf() - new Date(b.RegistrationDate).valueOf()
}